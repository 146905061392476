import { Injectable } from '@angular/core';
import { from, map, Observable, of, Subject, switchMap, tap } from 'rxjs';
import { ApiService } from '../api/api.service';
import axios from 'axios';
import { PhotoResizeOutput, ResidentPhoto } from '../../models/file.model';
import { AppStateService } from '../app-state/app-state.service';

interface UploadProgress {
  progress: number;
  total: number;
  loaded: number;
  estimated: number;
}

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(
    private apiService: ApiService,
    private appState: AppStateService,
  ) { }


  getSignedUrl(input: { key: string, action: 'GET' | 'PUT' }): Observable<string> {
    const statement = `
      query getSignedUrl($input: GetSignedUrlInput!) {
        getSignedUrl(input: $input)
      }
    `;
    return this.apiService
      .graphql<string>({ statement, variables: { input }, type: 'getSignedUrl' })
  }


  getFileBuffer(url: string): Observable<File> {
    return from(axios.get(url, {
      responseType: 'blob'
    })).pipe(
      map(response => response.data)
    );
  }

  uploadResidentPhoto({ key, file}: { key: string, file: File }): Observable<UploadProgress> {
    return this.getSignedUrl({ key, action: 'PUT' })
      .pipe(
        switchMap(url => {
          return this.upload(url, file)
        }),
      );
  }

  resizeResidentPhoto(input: { filename: string, extension: string, path: string }): Observable<PhotoResizeOutput> {

    const statement = `
      mutation resizeResidentPhoto($input: ResizeResidentPhotoInput!) {
        resizeResidentPhoto(input: $input) {
          size
          width
          height
          format
          hasThumbnail
        }
      }
    `;
    return this.apiService
      .graphql<PhotoResizeOutput>({ statement, variables: { input }, type: 'resizeResidentPhoto' })
  }

  getResidentPhotos(input: { residentId: string }): Observable<ResidentPhoto[]> {
    const statement = `
      query getResidentPhotos($residentId: ID!) {
        getResidentPhotos(residentId: $residentId) {
          id
          filename
          caption
          residentId
          type
          hasThumbnail
        }
      }
    `;
    return this.apiService
      .graphql<ResidentPhoto[]>({ statement, variables: input, type: 'getResidentPhotos' })
  }

  deleteResidentPhoto(input: { id: string, residentId: string, facilityId: string, filename: string }): Observable<boolean> {
    const statement = `
      mutation deleteResidentPhoto($input: DeleteResidentPhotoInput!) {
        deleteResidentPhoto(input: $input)
      }
    `;
    return this.apiService
      .graphql<boolean>({ statement, variables: { input }, type: 'deleteResidentPhoto' })
      .pipe(
        tap(() => {
          const residentPhotos = this.appState.get<ResidentPhoto[]>('residentPhotos').filter(photo => photo.id !== input.id);
          this.appState.setState('residentPhotos', residentPhotos);
        })
      )
  }

  updateResidentPhoto(input: { id: string, residentId: string, caption: string }): Observable<boolean> {
    const statement = `
      mutation updateResidentPhoto($input: UpdateResidentPhotoInput!) {
        updateResidentPhoto(input: $input)
      }
    `;
    return this.apiService
      .graphql<boolean>({ statement, variables: { input }, type: 'updateResidentPhoto' })
      .pipe(
        tap(() => {
          const residentPhotos = this.appState.get<ResidentPhoto[]>('residentPhotos');
          const photo = residentPhotos.find(photo => photo.id === input.id);
          if (photo) {
            photo.caption = input.caption;
            this.appState.setState('residentPhotos', residentPhotos);
          }
        })
      )
  }

  private upload(url: string, file: File): Observable<UploadProgress> {
    const res = new Subject<{ progress: number, total: number, loaded: number, estimated: number }>();
    axios.put(url, file, {
      headers: {
        'Content-Type': file.type
      }, onUploadProgress: (event => {
        res.next({
          progress: event.progress,
          loaded: event.loaded,
          total: event.total,
          estimated: event.estimated
        })
      })
    }).catch(error => {
      console.log(error);
    })
    return res.asObservable();
  }


  addResidentPhoto(input: { type: string, filename: string, size: number, key: string, residentId: string, hasThumbnail: boolean }) {
    const statement = `
      mutation addResidentPhoto($input: addResidentPhotoInput!) {
        addResidentPhoto(input: $input)
      }
    `;
    return this.apiService
      .graphql<any>({ statement, variables: { input }, type: 'addResidentPhoto' })
  }



}
